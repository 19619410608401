import React, {
  useState,
  useCallback,
  useEffect,
  Children,
  forwardRef,
  type ReactNode,
  type Ref,
} from 'react';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import classNames from 'classnames';
import {
  type ResponsiveValue,
  type ResponsiveSizeValue,
} from '../_internal/styling';
import Box from '../Box';
import IconButton from '../IconButton';
import { IconChevronLeft24, IconChevronRight24 } from '../icons';
import styles from './carousel.module.scss';
import ResponsiveBase from '../ResponsiveBase/ResponsiveBase';

export interface CarouselProps {
  /**
   * Used to label the Carousel region. Required to be unique if there are multiple carousels on a page.
   */
  ariaLabel: string;
  /**
   * The position of the control buttons (prev, next) from the top of the carousel.
   *
   * Useful when aligning controls with imagery, eg. SkuCards
   *
   * @default '50%'
   */
  controlsOffsetTop?: ResponsiveValue<string | number>;
  /**
   * Carousel Slides
   */
  children: ReactNode;
  /**
   * Extends the sides of the carousel beyond its containing box by the specified amount. Useful for
   * allowing the carousel to have a full-bleed width, particularly on small devices. The value
   * specified should match the gutter size of its container.
   *
   * E.g. `{ sm: 1, lg: 0 }`
   */
  gutterOverflow?: ResponsiveSizeValue;
  /**
   * Sets the width of the carousel elements.
   * Accepts any value that can be used as a width in CSS.
   * Value can be responsive, e.g., `{ sm: "300px", md: "500px" }`.
   */
  slideWidth: ResponsiveSizeValue;
  /**
   * Used to disable navigation controls and swiping. Useful if the Carousel is rendering a loading state.
   */
  withoutControls?: boolean;
}

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

// Prev/Next button implementation adapted form Embla's generator:
// https://www.embla-carousel.com/examples/generator/
export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setPrevBtnDisabled(!api.canScrollPrev());
    setNextBtnDisabled(!api.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

/**
 * Carousels are used to display serial content that is related by either type or theme.
 *For example, a carousel may be used for displaying multiple SKU cards or images, video,
 * and outfits for a specific SKU on the PDP.
 */
const Carousel = forwardRef(
  (
    {
      ariaLabel,
      controlsOffsetTop,
      children,
      gutterOverflow,
      slideWidth,
      withoutControls = false,
    }: CarouselProps,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
      align: 'start',
      slidesToScroll: 'auto',
      active: !withoutControls,
      dragThreshold: 0.15,
    });

    const {
      prevBtnDisabled,
      nextBtnDisabled,
      onPrevButtonClick,
      onNextButtonClick,
    } = usePrevNextButtons(emblaApi);

    const prevButton = prevBtnDisabled ? null : (
      <Box className={styles['control-left']}>
        <IconButton
          aria-label="Previous slide"
          variant="knockout"
          onClick={onPrevButtonClick}
        >
          <IconChevronLeft24 />
        </IconButton>
      </Box>
    );

    const nextButton = nextBtnDisabled ? null : (
      <Box className={styles['control-right']}>
        <IconButton
          aria-label="Next slide"
          variant="knockout"
          onClick={onNextButtonClick}
        >
          <IconChevronRight24 />
        </IconButton>
      </Box>
    );

    const slidesCount = Children.count(children);

    return (
      <ResponsiveBase
        properties={{}}
        variables={{
          '--slide-width': { responsiveValue: slideWidth },
          '--slide-spacing': { responsiveValue: { sm: '16px', lg: '24px' } },
          '--gutter-overflow': { responsiveValue: gutterOverflow },
          '--controls-offset': { responsiveValue: controlsOffsetTop },
        }}
      >
        {({ className, style }) => (
          <section
            ref={ref}
            data-testid="carousel-container"
            // For accessibility patterns, see: https://www.w3.org/WAI/ARIA/apg/patterns/carousel
            aria-label={ariaLabel}
            aria-roledescription="carousel"
            className={classNames(className, styles['carousel-container'])}
            style={style}
          >
            <div className={styles.gutters}>
              {withoutControls ? null : prevButton}
              {withoutControls ? null : nextButton}
              <div data-testid="carousel-slider" ref={emblaRef}>
                <div className={styles.slider}>
                  {Children.map(children, (slide, index) => (
                    <div
                      role="group"
                      aria-roledescription="slide"
                      className={styles.slide}
                      aria-label={`${index + 1} of ${slidesCount}`}
                    >
                      {slide}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </ResponsiveBase>
    );
  },
);

export default Carousel;
