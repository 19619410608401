import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconChevronRight24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.15643 3.48865L9.72329 4.05316L13.4947 7.80894L17.2661 11.5647L17.8354 12.1317L17.2661 12.6985L13.5052 16.4427L9.7443 20.187L9.17736 20.7514L8.0485 19.6175L8.61544 19.0531L12.3763 15.3089L15.5678 12.1315L12.3657 8.94266L8.59427 5.18688L8.02741 4.62237L9.15643 3.48865Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconChevronRight24(props, ref);
});
export default ForwardRef;