import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconChevronLeft24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M14.8436 20.5114L14.2767 19.9469L10.5053 16.1911L6.73386 12.4353L6.16455 11.8684L6.73394 11.3015L10.4948 7.5573L14.2557 3.81308L14.8226 3.24865L15.9515 4.38253L15.3846 4.94696L11.6237 8.69118L8.43218 11.8685L11.6343 15.0574L15.4057 18.8132L15.9726 19.3777L14.8436 20.5114Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconChevronLeft24(props, ref);
});
export default ForwardRef;